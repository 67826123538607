const FilterAdmin = ({
  setFilter = () => {},
  setFilterValue = () => {},
  selected = ""
}) => {
  return (
    <div>
      <span>Сортування: </span>
      <select
        onChange={(e) => {
          const value = e.target.value.split("_");
          setFilter(value[0]);
          setFilterValue(value[1]);
        }}
        style={{
          borderRadius: "50px",
          padding: "5px",
          fontSize: "14px",
          border: "1px solid #e8e8e8",
          outline: "none",
          boxShadow: "0 0px 2px rgba(0, 0, 0, 0.1)",
        }}
      >
        <option selected={selected === ""} value={""} style={{ fontSize: "14px" }}>
          Всі
        </option>
        <option selected={selected === "isAvailable_true"} value={"isAvailable_true"} style={{ fontSize: "14px" }}>
          В наявності
        </option>
        <option selected={selected === "isAvailable_false"} value={"isAvailable_false"} style={{ fontSize: "14px" }}>
          Немає в наявності
        </option>
        <option selected={selected === "recommended_true"} value={"recommended_true"} style={{ fontSize: "14px" }}>
          Рекомендовано
        </option>
      </select>
    </div>
  );
};

export default FilterAdmin;
