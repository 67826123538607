import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import s from "../../styles/icons.module.css";
import Overlay from "./overlay";
import s2 from "../../styles/overlay.module.css";
import DescriptionAdmin from "./description_admin";
import SpecsAdmin from "./specs_admin";
import OrderInfoAdmin from "./order_info_admin";
import { adminAPI, publicAPI } from "../../api/api";
import HOST from "../../env";

const ProductDetailsAdmin = () => {
  const [element, setElement] = useState("description");
  const location = useLocation().pathname.split("/");
  const _id = location[location.length - 1];
  const [editOn, setEditOn] = useState(false);
  const [product, setProduct] = useState({});
  const [text, setText] = useState(product[element]);
  const textAreaRef = useRef(null);
  const getProduct = async () => {
    try {
      const res = await publicAPI.getProducts(_id);
      setProduct(res.data.products[0]);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    setText(product[element]);
  }, [product]);

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      const textarea = event.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      textarea.value =
        textarea.value.substring(0, start) +
        "\t" +
        textarea.value.substring(end);
      textarea.selectionEnd = end + 1;
      setText(textarea.value);
    }
  };

  useEffect(() => {
    setText(product[element]);
  }, [element]);

  useEffect(() => {
    if (textAreaRef.current) {
      const savedScrollPosition = document.documentElement.scrollTop;
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
      window.scrollTo(0, savedScrollPosition);
    }
  }, [text, editOn]);

  if (product)
    return (
      <div style={{ display: "flex", padding: "20px 0" }}>
        <div style={{ flexGrow: "1" }}>
          <div style={{ margin: "0 20px" }}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                height: "430px",
              }}
            >
              {product?.pictures?.length &&
                product.pictures.map((pictures, index1) => {
                  pictures = pictures.split("\n");
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        minWidth: "200px",
                        width: "100%",
                      }}
                    >
                      {pictures.map((picture, index2) => (
                        <div
                          className={s2.container}
                          style={{
                            position: "relative",
                            border: "1px solid #eaeaea",
                            height: pictures.length > 1 ? "49%" : "100%",
                            display: "flex",
                          }}
                        >
                          <div
                            className={s2.child}
                            style={{
                              width: "100%",
                              justifyContent: "center",
                              backgroundImage: `url(${HOST}/${picture})`,
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundColor: "white",
                            }}
                          ></div>
                          <Overlay
                            type={pictures.length === 1 ? "Split" : "Join"}
                            indexes={[index1, index2]}
                            product={product}
                            _id={_id}
                            getProduct={getProduct}
                          ></Overlay>
                        </div>
                      ))}
                    </div>
                  );
                })}
            </div>
            <div style={{ display: "flex", margin: "20px 0" }}>
              <div
                className="borderTop"
                onClick={(e) => {
                  e.currentTarget.parentNode.childNodes.forEach((child) =>
                    child.classList.remove("borderTop")
                  );
                  e.currentTarget.classList.add("borderTop");
                  setElement("description");
                  setEditOn(false);
                }}
                style={{
                  width: "33%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                  border: "0.5px solid #e8e8e8",
                  background: "#f7f7f7",
                  textAlign: "center",
                }}
              >
                <i className={s.content16} style={{ marginBottom: "5px" }}></i>
                <span>Опис</span>
              </div>
              <div
                onClick={(e) => {
                  e.currentTarget.parentNode.childNodes.forEach((child) =>
                    child.classList.remove("borderTop")
                  );
                  e.currentTarget.classList.add("borderTop");
                  setElement("specs");
                  setEditOn(false);
                }}
                style={{
                  width: "33%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                  border: "0.5px solid #e8e8e8",
                  background: "#f7f7f7",
                  textAlign: "center",
                }}
              >
                <i className={s.content17} style={{ marginBottom: "5px" }}></i>
                <span>Характеристики</span>
              </div>
              <div
                onClick={(e) => {
                  e.currentTarget.parentNode.childNodes.forEach((child) =>
                    child.classList.remove("borderTop")
                  );
                  e.currentTarget.classList.add("borderTop");
                  setElement("orderInfo");
                  setEditOn(false);
                }}
                style={{
                  width: "33%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                  border: "0.5px solid #e8e8e8",
                  background: "#f7f7f7",
                  textAlign: "center",
                }}
              >
                <i className={s.content18} style={{ marginBottom: "5px" }}></i>
                <span>Інформація про замовлення</span>
              </div>
            </div>
            {editOn ? (
              <>
                <div
                  style={{ display: "flex", marginBottom: "20px", gap: "10px" }}
                >
                  <button
                    onClick={async () => {
                      try {
                        if (element === "description")
                          await adminAPI.editProductDetails(_id, {
                            description: text,
                          });
                        else if (element === "specs")
                          await adminAPI.editProductDetails(_id, {
                            specs: text,
                          });
                        else if (element === "orderInfo")
                          await adminAPI.editProductDetails(_id, {
                            orderInfo: text,
                          });
                        getProduct();
                        setEditOn(false);
                      } catch (err) {console.log(err);}
                    }}
                    style={{
                      backgroundColor: "rgb(106, 180, 22)",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    Зберегти
                  </button>
                  <button
                    onClick={() => setEditOn(false)}
                    style={{
                      backgroundColor: "rgb(106, 180, 22)",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    Скасувати
                  </button>
                </div>

                <textarea
                  value={text}
                  ref={textAreaRef}
                  onChange={(e) => setText(e.target.value)}
                  onKeyDown={handleKeyDown}
                  style={{
                    width: "100%",
                  }}
                />
              </>
            ) : (
              <>
                {element === "description" ? (
                  <DescriptionAdmin
                    data={product?.description}
                    productDescription={true}
                    setEditOn={setEditOn}
                  ></DescriptionAdmin>
                ) : element === "specs" ? (
                  <SpecsAdmin
                    data={product?.specs}
                    setEditOn={setEditOn}
                  ></SpecsAdmin>
                ) : (
                  element === "orderInfo" && (
                    <OrderInfoAdmin
                      orderInfo={product?.orderInfo}
                      setEditOn={setEditOn}
                    ></OrderInfoAdmin>
                  )
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
};

export default ProductDetailsAdmin;
