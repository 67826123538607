import React, { useEffect, useState } from "react";
import s from "../styles/icons.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import Flag from "./flag";
import Pagination from "./pagination";
import { publicAPI } from "../api/api";
import HOST from "../env";
import Sort from "./sort";
import useConditionalNavigate from "./custom_hooks/use_conditional_navigate";

const Products = ({
  categoryId = "",
  cart = [],
  minPrice = "",
  maxPrice = "",
  filterValue = false,
  setCart = () => {},
  setFilterOn = () => {},
  contacts = "",
}) => {
  const location = useLocation().pathname.split("/");
  const _id = location[location.length - 1];
  const pageSizes = [4, 8, 12, 16, 32, 64];
  const [products, setProducts] = useState([]);
  const [pageSize, setPageSize] = useState(pageSizes[pageSizes.length - 1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [sort, setSort] = useState("title");
  const [order, setOrder] = useState("1");

  const getProducts = async (query = "") => {
    try {
      const res = await publicAPI.getProducts(
        "",
        `&filter=isAvailable&filterValue=${
          filterValue === true ? filterValue : ""
        }&minPrice=${minPrice}&maxPrice=${maxPrice}&categoryId=${categoryId}&currentPage=${currentPage}&pageSize=${pageSize}&sort=${sort}&order=${order}&${query}`
      );
      setProducts([...res.data.products]);
      setTotalCount(res.data.totalCount);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (categoryId !== _id) return;
    setFilterOn(true);
    getProducts();
    return () => {
      setFilterOn(false);
    };
  }, [
    currentPage,
    pageSize,
    sort,
    order,
    categoryId,
    minPrice,
    maxPrice,
    filterValue,
  ]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {" "}
        <Sort setSort={setSort} setOrder={setOrder}></Sort>
        <Pagination
          totalCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        ></Pagination>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginBottom: "30px",
          gap: "15px",
        }}
      >
        {products.map((product) => (
          <ProductPreview
            {...product}
            cart={cart}
            setCart={setCart}
            contacts={contacts}
          ></ProductPreview>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ marginBottom: "30px" }}>
          <span>Товарів на сторінці: </span>
          <select
            onChange={(e) => {
              const value = e.target.value;
              setPageSize(+value);
            }}
            style={{
              padding: "5px",
              fontSize: "14px",
              border: "1px solid #e8e8e8",
              outline: "none",
              boxShadow: "0 0px 2px rgba(0, 0, 0, 0.1)",
            }}
          >
            <option
              selected={pageSize === pageSizes[0] ? true : false}
              value={pageSizes[0]}
              style={{ fontSize: "14px" }}
            >
              {pageSizes[0]}
            </option>
            <option
              selected={pageSize === pageSizes[1] ? true : false}
              value={pageSizes[1]}
              style={{ fontSize: "14px" }}
            >
              {pageSizes[1]}
            </option>
            <option
              selected={pageSize === pageSizes[2] ? true : false}
              value={pageSizes[2]}
              style={{ fontSize: "14px" }}
            >
              {pageSizes[2]}
            </option>
            <option
              selected={pageSize === pageSizes[3] ? true : false}
              value={pageSizes[3]}
              style={{ fontSize: "14px" }}
            >
              {pageSizes[3]}
            </option>
            <option
              selected={pageSize === pageSizes[4] ? true : false}
              value={pageSizes[4]}
              style={{ fontSize: "14px" }}
            >
              {pageSizes[4]}
            </option>
            <option
              selected={pageSize === pageSizes[5] ? true : false}
              value={pageSizes[5]}
              style={{ fontSize: "14px" }}
            >
              {pageSizes[5]}
            </option>
          </select>
        </div>
        <Pagination
          totalCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        ></Pagination>
      </div>
    </div>
  );
};

const ProductPreview = ({
  title = "",
  imageURL = "",
  price = "",
  discountPrice = "",
  discountPeriod = { start: null, end: null },
  isAvailable = "",
  toOrder = { on: false, dayCount: null },
  code = "",
  _id = "",
  flag = "",
  cart = [],
  setCart = () => {},
  contacts = "",
}) => {
  const isPeriodValid = () => {
    const startNumber = new Date(discountPeriod?.start).getTime();
    const endNumber = new Date(discountPeriod?.end).getTime();
    const nowNumber = new Date().getTime();
    return nowNumber < endNumber && endNumber - startNumber > 0;
  };
  let phone = "";
  if (contacts) {
    contacts.split("\n\n").map((contact) => {
      contact = contact.split("\n");
      if (contact[0] === "Телефони") phone = contact[1];
    });
  }
  const navigate = useConditionalNavigate();
  return (
    <div
      style={{
        width: "240px",
        height: "500px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {flag && <Flag text={flag}></Flag>}
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          onClick={(e) => navigate("/product/" + _id, e)}
          src={`${HOST}/${imageURL}`}
          style={{ maxHeight: "220px", maxWidth: "100%" }}
        ></img>
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {code && (
          <span style={{ fontSize: "12px", color: "#ACACAC" }}>
            Код: {code}
          </span>
        )}
        <span
          onClick={(e) => navigate("/product/" + _id, e)}
          className="text_hover"
        >
          {title}
        </span>
        {isAvailable ? (
          <span style={{ fontSize: "14px", color: "#6AB416" }}>
            В наявності
          </span>
        ) : toOrder?.on && toOrder?.dayCount ? (
          <span style={{ fontSize: "14px", color: "#ACACAC" }}>
            Під замовлення ({toOrder?.dayCount} днів)
          </span>
        ) : (
          <span style={{ fontSize: "14px", color: "#E75539" }}>
            Немає в наявності
          </span>
        )}
        {(!discountPrice || !isPeriodValid()) && (
          <span
            style={{
              padding: "7px",
              backgroundColor: "#FFF3B5",
              borderRadius: "5px",
              width: "fit-content",
            }}
          >
            {price + " ₴"}
          </span>
        )}
        {discountPrice && isPeriodValid() && (
          <>
            <span style={{ color: "red" }}>
              {discountPeriod.start + " - " + discountPeriod.end}
            </span>
            <div
              style={{
                width: "fit-content",
                backgroundColor: "#FFF3B5",
                borderRadius: "5px",
              }}
            >
              <span
                style={{
                  padding: "7px",
                  width: "fit-content",
                  position: "relative",
                  display: "inline-block",
                  color: "black",
                }}
              >
                {price}
                <span
                  style={{
                    content: '""',
                    position: "absolute",
                    top: "50%",
                    left: "-10%",
                    width: "120%",
                    height: "2px",
                    background: "red",
                    transform: "rotate(45deg)",
                    transformOrigin: "center",
                  }}
                ></span>
              </span>
              <span
                style={{
                  padding: "7px",
                }}
              >
                {discountPrice + " ₴"}
              </span>
            </div>
          </>
        )}

        {isAvailable || (toOrder?.on && toOrder.dayCount) ? (
          cart.filter((product) => product.title === title).length > 0 ? (
            <button
              disabled={true}
              style={{
                width: "100%",
                backgroundColor: "rgb(172, 172, 172)",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                height: "30px",
              }}
            >
              В корзині
            </button>
          ) : (
            <button
              onClick={() => {
                let index;
                cart.find((product, i) => {
                  if (product.title === title) index = i;
                });
                const newCart = [...cart];
                if (index !== undefined) {
                  newCart[index].count += 1;
                  setCart(newCart);
                } else {
                  newCart.push({
                    _id,
                    title,
                    imageURL,
                    isAvailable,
                    price,
                    discountPrice,
                    discountPeriod,
                    count: 1,
                  });
                  setCart(newCart);
                }
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#7ED61A";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#6AB416";
              }}
              style={{
                width: "100%",
                backgroundColor: "#6AB416",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                height: "30px",
              }}
            >
              Купити
            </button>
          )
        ) : (
          <span className={s.content9}>{phone}</span>
        )}
      </div>
    </div>
  );
};

export default Products;
