import { useContext, useEffect, useState } from "react";
import Aboutus from "./components/aboutus";
import Feedback from "./components/feedback";
import Footer from "./components/footer";
import Header from "./components/header";
import Menu from "./components/menu";
import Slider from "./components/slider";
import Underheader from "./components/underheader";
import Caption from "./components/caption";
import { Route, Routes } from "react-router-dom";
import Contacts from "./components/contacts";
import Schedule from "./components/schedule";
import Contacts2 from "./components/contacts2";
import Delivery from "./components/delivery";
import ScheduleModal from "./components/schedule_modal";
import Description from "./components/description";
import ProductDetails from "./components/product_details";
import CartModal from "./components/cart_modal";
import Popup from "./components/popup";
import Filter from "./components/filter";
import Products from "./components/products";
import Categories from "./components/categories";
import Reccomendations from "./components/recomendations";
import { publicAPI } from "./api/api";

const App = () => {
  const [modalOn, setModalOn] = useState(false);
  const [sidebarOn, setSidebarOn] = useState(true);
  const [cart, setCart] = useState([]);
  const [cartOn, setCartOn] = useState(false);
  const [filterOn, setFilterOn] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [info, setInfo] = useState({});
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [filterValue, setFilterValue] = useState(false);
  useEffect(() => {
    setCart(JSON.parse(localStorage.getItem("cart") || "[]"));
    const getInfo = async () => {
      try {
        const res = await publicAPI.getInfo();
        setInfo(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getInfo();
  }, []);
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  return (
    <>
      {cartOn && (
        <CartModal
          cart={cart}
          setCart={setCart}
          setCartOn={setCartOn}
        ></CartModal>
      )}
      {modalOn && (
        <ScheduleModal
          data={info?.schedule}
          setModalOn={setModalOn}
        ></ScheduleModal>
      )}
      <Header cart={cart} setCartOn={setCartOn}></Header>
      <Popup schedule={info?.schedule}></Popup>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "1320px" }}>
          <Underheader
            cart={cart}
            data={info?.contacts}
            setModalOn={setModalOn}
            setCartOn={setCartOn}
          ></Underheader>
          <Menu></Menu>
          <div style={{ display: "flex", backgroundColor: "#fff" }}>
            {sidebarOn && (
              <aside style={{ width: "240px" }}>
                {filterOn && (
                  <Filter
                    minPrice={minPrice}
                    maxPrice={maxPrice}
                    filterValue={filterValue}
                    setMinPrice={setMinPrice}
                    setMaxPrice={setMaxPrice}
                    setFilterValue={setFilterValue}
                  ></Filter>
                )}
                <Contacts data={info?.contacts}></Contacts>
                <Schedule data={info?.schedule}></Schedule>
              </aside>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: sidebarOn && "1080px",
                width: "100%",
              }}
            >
              {sidebarOn && !filterOn && <Slider></Slider>}
              <Routes>
                <Route
                  path="/"
                  element={
                    <div style={{ padding: "20px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          marginBottom: "50px",
                        }}
                      >
                        <Categories setCategoryId={setCategoryId}></Categories>
                      </div>
                      <Aboutus
                        data={{
                          description: info?.aboutUs?.description || "",
                          companyInfo: "",
                        }}
                      ></Aboutus>
                      <Caption text={"Вітрина"} margin="30px 0"></Caption>
                      <Products
                        categoryId={categoryId}
                        minPrice={minPrice}
                        maxPrice={maxPrice}
                        filterValue={filterValue}
                        cart={cart}
                        setCart={setCart}
                        contacts={info?.contacts}
                      ></Products>
                    </div>
                  }
                ></Route>
                <Route
                  path="/categories/:any"
                  element={
                    <div style={{ padding: "20px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          marginBottom: "50px",
                        }}
                      >
                        <Categories setCategoryId={setCategoryId}></Categories>
                      </div>
                      <Products
                        categoryId={categoryId}
                        setFilterOn={setFilterOn}
                        filterValue={filterValue}
                        minPrice={minPrice}
                        maxPrice={maxPrice}
                        cart={cart}
                        setCart={setCart}
                        contacts={info?.contacts}
                      ></Products>
                    </div>
                  }
                ></Route>
                <Route
                  path="/aboutus"
                  element={
                    <div style={{ padding: "20px" }}>
                      <Caption text="Про нас" margin="0 0 20px 0"></Caption>
                      <Aboutus data={info?.aboutUs}></Aboutus>
                    </div>
                  }
                ></Route>
                <Route
                  path="/contacts"
                  element={
                    <div style={{ padding: "20px" }}>
                      <Contacts2 data={info?.contacts}></Contacts2>{" "}
                    </div>
                  }
                ></Route>
                <Route
                  path="/delivery"
                  element={
                    <div style={{ padding: "20px" }}>
                      <Delivery data={info?.delivery}></Delivery>
                    </div>
                  }
                ></Route>
                <Route
                  path="/return"
                  element={
                    <div style={{ padding: "20px" }}>
                      <Description
                        data={info?.returnPolicy}
                        captionText="Повернення та обмін"
                      ></Description>
                    </div>
                  }
                ></Route>
                <Route
                  path="/product/:any"
                  element={
                    <ProductDetails
                      setSidebarOn={setSidebarOn}
                      setCart={setCart}
                      cart={cart}
                      contacts={info?.contacts}
                    ></ProductDetails>
                  }
                ></Route>
              </Routes>
              {sidebarOn && (
                <>
                  <Reccomendations captionText="Ви переглядали"></Reccomendations>
                  <Reccomendations captionText="Ми рекомендуємо"></Reccomendations>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <Feedback></Feedback> */}
      <Footer></Footer>
    </>
  );
};

export const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
};

export const getCookieValue = (name) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${name}=`)) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};

export const deleteCookie = (name) => {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

export default App;
