import { useEffect, useState } from "react";
import s from "../styles/cart_modal.module.css";
import CartItem from "./cart_item";
import { publicAPI } from "../api/api";
import GetContacts from "./get_contacts";

const CartModal = ({ cart = [], setCart = () => {}, setCartOn = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isPeriodValid = (discountPeriod) => {
    const startNumber = new Date(discountPeriod?.start).getTime();
    const endNumber = new Date(discountPeriod?.end).getTime();
    const nowNumber = new Date().getTime();
    return nowNumber < endNumber && endNumber - startNumber > 0;
  };
  const getProducts = async () => {
    try {
      const res = await publicAPI.getProducts(
        "",
        `filter=_ids&filterValue=${cart.map((product) => product._id)}`
      );
      setCart(
        cart.filter((product) => {
          if (res.data.products.find((prod) => product._id === prod._id))
            return product;
        })
      );
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    getProducts();
    return () =>
      (document.getElementsByTagName("body")[0].style.overflow = "auto");
  }, []);
  return (
    <>
      {isOpen && (
        <GetContacts
          isOpen={isOpen}
          cart={cart}
          setIsOpen={setIsOpen}
          setCartOn={setCartOn}
          setCart={setCart}
        ></GetContacts>
      )}
      {!isOpen && (
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, .3)",
            zIndex: "2",
          }}
        >
          <div
            style={{
              position: "absolute",
              right: "0",
              background: "white",
              borderTopLeftRadius: "16px",
              borderBottomLeftRadius: "16px",
              width: "400px",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                padding: "8px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <h1
                  style={{
                    width: "300px",
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "700",
                  }}
                >
                  Кошик
                </h1>
                <div
                  onClick={() => setCartOn(false)}
                  className={s.close}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "fit-content",
                    padding: "8px",
                    borderRadius: "8px",
                  }}
                >
                  <svg
                    style={{ borderRadius: "8px", outline: "none" }}
                    width="24px"
                    height="24px"
                    fill="none"
                    viewBox="0 0 24 24"
                    data-testid="SvgClose"
                    data-qaid="SvgClose"
                    class="_2uocW LoRyP"
                    focusable="false"
                    aria-hidden="true"
                    tabindex="-1"
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M4.414 3A1 1 0 0 0 3 4.414L10.586 12 3 19.586A1 1 0 1 0 4.414 21L12 13.414 19.586 21A1 1 0 0 0 21 19.586L13.414 12 21 4.414A1 1 0 0 0 19.586 3L12 10.586 4.414 3Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div style={{ padding: "12px" }}>
                <div style={{ background: "white" }}>
                  {cart.length > 0 &&
                    cart.map((product) => (
                      <CartItem
                        cart={cart}
                        setCart={setCart}
                        {...product}
                      ></CartItem>
                    ))}
                </div>
                {cart.length > 0 && (
                  <div style={{ marginTop: "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span style={{ fontWeight: "500" }}>
                        До сплати без доставки:
                      </span>
                      <span style={{ fontWeight: "500", fontSize: "18px" }}>
                        {cart.reduce((acum, product) => {
                          const price =
                            product?.discountPrice &&
                            isPeriodValid(product?.discountPeriod)
                              ? product.discountPrice
                              : product.price;
                          return acum + +price * product.count;
                        }, 0) + " ₴"}
                      </span>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <button
                        onClick={async () => {
                          setIsOpen(true);
                        }}
                        style={{
                          background: "#69b316",
                          color: "white",
                          border: "none",
                          width: "100%",
                          padding: "12px 0",
                          borderRadius: "8px",
                          fontWeight: "500",
                        }}
                      >
                        Оформити замовлення
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CartModal;
