import axios from "axios";
import HOST from "../env";

const instance = axios.create({
  withCredentials: true,
  baseURL: `${HOST}/api/`,
});

export const publicAPI = {
  getInfo() {
    return instance.get(`info`);
  },
  getProducts(_id = "", query = "") {
    return instance.get(`products/${_id}?${query}`);
  },
  getRange(_id = "") {
    return instance.get(`range/${_id}`);
  },
  getCategory(_id = "", query = "") {
    return instance.get(`categories/${_id}?${query}`);
  },
  paymentInit(cart = {}, data = {}, query = "") {
    return instance.post(`payment-init?${query}`, { cart, data });
  },
};

export const adminAPI = {
  addCategory(_id = "", newCategory) {
    return instance.post(`administrator/categories/add/${_id}`, newCategory);
  },
  deleteCategory(_id = "") {
    return instance.delete(`administrator/categories/delete/${_id}`);
  },
  editCategory(_id = "", formData) {
    return instance.put(`administrator/categories/edit/${_id}`, formData);
  },

  addProduct(product = {}) {
    return instance.post(`administrator/products/add`, product);
  },
  deleteProduct(_id = "") {
    return instance.delete(`administrator/products/delete/${_id}`);
  },
  editProductPreview(_id = "", formData) {
    return instance.put(`administrator/products/edit-preview/${_id}`, formData);
  },
  editProductDetails(_id = "", formData) {
    return instance.put(`administrator/products/edit-details/${_id}`, formData);
  },
  login(formData) {
    return instance.post(`administrator/login`, formData);
  },
  validateToken() {
    return instance.get(`administrator/validate-token`);
  },
  exportExcel() {
    return instance.get(`administrator/export-excel`, {
      responseType: "blob",
    });
  },
};
