import React, { useState } from "react";
import s from "../styles/icons.module.css";
import SearchResults from "./search_results";
import { publicAPI } from "../api/api";
import { useNavigate } from "react-router-dom";
import useConditionalNavigate from "./custom_hooks/use_conditional_navigate";

const Underheader = ({
  data = "",
  setModalOn,
  cart = [],
  setCartOn = () => {},
}) => {
  const navigate = useConditionalNavigate();
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [filter, setFilter] = useState([]);
  const getProducts = async (query = "") => {
    try {
      const res = await publicAPI.getProducts("", `${query}`);
      setFilter(res.data.products);
    } catch (err) {
      console.log(err);
    }
  };
  let location = "",
    number = "",
    company = "";
  data.split("\n\n").map((block) => {
    block = block.split("\n");
    if (/Компанія/.test(block[0])) location = block[1];
    else if (/Телефони/.test(block[0])) number = block[1];
    else if (/Адреса/.test(block[0])) company = block[1];
  });
  return (
    <div
      style={{
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        marginTop: "20px",
      }}
    >
      <div
        style={{ display: "flex", justifyContent: "space-between", gap: "5px" }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div
              className={s.content}
              style={{
                backgroundColor: "#f5643e",
                color: "#fff",
                textAlign: "center",
                fontSize: "16px",
                width: "22px",
                height: "22px",
                lineHeight: "22px",
                borderRadius: "3px",
              }}
            ></div>
            <span>{company}</span>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div
              className={s.content2}
              style={{
                backgroundColor: "#f5643e",
                color: "#fff",
                textAlign: "center",
                fontSize: "16px",
                width: "22px",
                height: "22px",
                lineHeight: "22px",
                borderRadius: "3px",
              }}
            ></div>
            <span>
              {number.slice(0, 4)}
              <span style={{ color: "#69B316" }}>
                {" (" + number.slice(4, 6) + ") "}
              </span>
              {number.slice(6, 9)}-{number.slice(9, 11)}-{number.slice(11, 13)}
            </span>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div
              className={s.content3}
              style={{
                backgroundColor: "#f5643e",
                color: "#fff",
                textAlign: "center",
                fontSize: "16px",
                width: "22px",
                height: "22px",
                lineHeight: "22px",
                borderRadius: "3px",
              }}
            ></div>
            <span
              onClick={() => {
                setModalOn(true);
                document.getElementsByTagName("body")[0].style.overflow =
                  "hidden";
              }}
              style={{ borderBottom: "1px dotted" }}
            >
              Графік роботи
            </span>
          </div>
          {/* <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div
              className={s.content4}
              style={{
                backgroundColor: "#f5643e",
                color: "#fff",
                textAlign: "center",
                fontSize: "16px",
                width: "22px",
                height: "22px",
                lineHeight: "22px",
                borderRadius: "3px",
              }}
            ></div>
            <span style={{ borderBottom: "1px dotted" }}>Залишити відгук</span>
          </div> */}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          onClick={(e) => navigate("/", e)}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            width: "500px",
            cursor: "pointer",
          }}
        >
          <img src="/images/4532869852_w350_h100_coffee-ride.webp"></img>
          <span style={{ borderLeft: "1px dotted #fff", padding: "0 5px" }}>
            {location}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <input
            placeholder="пошук по каталогу товарів..."
            style={{
              width: "500px",
              padding: "10px",
              borderRadius: "100px",
              border: "2px solid #f5643e",
              width: "500px",
            }}
            onInput={(e) => {
              clearTimeout(debounceTimer);
              setInputValue(e.target.value);
              if (e.target.value === "") return setFilter([]);
              setDebounceTimer(
                setTimeout(() => {
                  getProducts(`title=${e.target.value}`);
                }, 1000)
              );
            }}
            value={inputValue}
            onFocus={(e) => {
              e.preventDefault();
              e.target.style.outline = "none";
            }}
          ></input>
          {filter.length > 0 && (
            <SearchResults
              filter={filter}
              setFilter={setFilter}
              setInputValue={() => {
                setInputValue("");
              }}
            ></SearchResults>
          )}
          <div
            className={s.content5}
            style={{
              backgroundColor: "#f5643e",
              color: "#fff",
              textAlign: "center",
              fontSize: "16px",
              width: "42px",
              height: "42px",
              lineHeight: "42px",
              borderRadius: "50%",
              position: "relative",
              right: "40px",
            }}
          ></div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <div
            onClick={() => setCartOn(true)}
            className={s.content6}
            style={{
              position: "relative",
              backgroundColor: "#f5643e",
              color: "#fff",
              textAlign: "center",
              fontSize: "20px",
              width: "50px",
              height: "50px",
              lineHeight: "50px",
              borderRadius: "50%",
            }}
          >
            {cart.length > 0 && (
              <span
                style={{
                  position: "absolute",
                  top: "-6px",
                  left: "17px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                  fontWeight: "600",
                  height: "18px",
                  width: "18px",
                  background: "#66c571",
                  borderRadius: "50%",
                }}
              >
                {cart.length}
              </span>
            )}
          </div>
          <span
            onClick={() => setCartOn(true)}
            style={{ borderBottom: "1px dotted" }}
          >
            Кошик{" "}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Underheader;
