const Flag = ({text = ""}) => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <svg
        style={{ width: "20px", height: "20px" }}
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="100"
        viewBox="0 0 100 100"
      >
        <rect width="100" height="100" fill="#ffa500" />
        <polygon points="0, 0 50, 50 0, 100" fill="#fff" />
      </svg>

      <span
        style={{
          padding: "0 5px",
          backgroundColor: "#ffa500",
          width: "fit-content",
          color: "#fff",
        }}
      >
        {text}
      </span>
    </div>
  );
};

export default Flag
