import {  useState } from "react";
import s from "../styles/icons.module.css";

const Popup = ({schedule = {}}) => {
  const [popupOn, setPopupOn] = useState(true);
  const dateTime = new Date();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const currentDayName = daysOfWeek[dateTime.getDay()];
  let shceduleHours = schedule[currentDayName]?.match(/\d+/g) || [];
  shceduleHours =  shceduleHours.map((n) => +n);
  const currentHour = dateTime.getHours();
  const currentMinutes = dateTime.getMinutes();
  if (
    popupOn &&
    (currentHour < shceduleHours[0] ||
      currentHour > shceduleHours[2] ||
      (currentHour === shceduleHours[0] && currentMinutes < shceduleHours[1]) ||
      (currentHour === shceduleHours[2] && currentMinutes > shceduleHours[3]))
  )
    return (
      <div
        style={{
          background: "#fdf2cc",
          padding: "10px 40px",
          borderLeft: "10px solid rgb(255, 140, 56)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <div style={{ width: "30px", height: "30px", marginRight: "25px" }}>
          <svg
            style={{ color: "rgb(255, 140, 56)" }}
            class="b-info-banner__icon"
            viewBox="0 0 24 24"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g fill="currentColor" fill-rule="nonzero">
                <path d="M10.9084921,2.78216375 C11.9482635,2.20625071 13.2462954,2.54089334 13.8841246,3.52240345 L13.9669228,3.66023048 L21.725879,17.6684928 C21.9106772,18.0021332 22.0076302,18.3772743 22.0076302,18.7586748 C22.0076302,19.9495388 21.0824686,20.9243197 19.911679,21.003484 L19.7576302,21.0086748 L4.24187791,21.0086748 C3.86056733,21.0086748 3.48551176,20.9117674 3.15192815,20.7270522 C2.11208877,20.1512618 1.7070482,18.8734454 2.20060302,17.8120352 L2.27350053,17.668725 L10.0302967,3.66046274 C10.2348245,3.291099 10.5391525,2.98673515 10.9084921,2.78216375 Z M12.0004478,16.0017852 C11.4489016,16.0017852 11.0017852,16.4489016 11.0017852,17.0004478 C11.0017852,17.5519939 11.4489016,17.9991104 12.0004478,17.9991104 C12.5519939,17.9991104 12.9991104,17.5519939 12.9991104,17.0004478 C12.9991104,16.4489016 12.5519939,16.0017852 12.0004478,16.0017852 Z M11.9982876,7.99806092 C11.4854518,7.99824566 11.0629195,8.38443798 11.0053334,8.88179743 L10.9986476,8.99842097 L11.0004479,13.9993002 L11.0072176,14.1159189 C11.0651617,14.6132367 11.487972,14.9991248 12.0008078,14.9989403 C12.5136436,14.9987555 12.9361759,14.6125632 12.993762,14.1152038 L13.0004478,13.9985802 L12.9986476,8.99770102 L12.9918779,8.88108232 C12.9339337,8.38376446 12.5111234,7.99787644 11.9982876,7.99806092 Z"></path>
              </g>
            </g>
          </svg>
        </div>
        <div>
          <p>
            Зараз у компанії неробочий час. Замовлення та повідомлення будуть
            оброблені найближчого робочого дня
          </p>
        </div>
        <div
          onClick={() => {
            setPopupOn(false);
          }}
          className={s.content19}
          style={{ position: "absolute", right: "10px" }}
        ></div>
      </div>
    );
};

export default Popup;
