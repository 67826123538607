import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MyContext } from "..";
import Description from "./description";
import Specs from "./specs";
import s from "../styles/icons.module.css";
import ProductSidebar from "./product_sidebar";
import { publicAPI } from "../api/api";
import HOST from "../env";

const ProductDetails = ({
  setSidebarOn = () => {},
  cart = [],
  setCart = () => {},
  contacts = "",
}) => {
  const [element, setElement] = useState("description");
  const location = useLocation().pathname.split("/");
  const _id = location[location.length - 1];
  const [product, setProduct] = useState({});
  const [zoomedPicture, setZoomedPicture] = useState("");
  const getProduct = async () => {
    try {
      const res = await publicAPI.getProducts(_id);
      setProduct(res.data.products[0]);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getProduct();
  }, [_id]);
  useEffect(() => {
    setSidebarOn(false);
    return () => setSidebarOn(true);
  }, []);

  let viewedProducts =
    JSON.parse(localStorage.getItem("viewedProducts") || null) || [];
  viewedProducts = viewedProducts.filter((prod) => {
    return prod?.title !== product?.title;
  });
  if (Object.keys(product).length > 0) {
    viewedProducts.unshift(product);
    if (viewedProducts.length > 10) viewedProducts.splice(10);
    localStorage.setItem("viewedProducts", JSON.stringify(viewedProducts));
  }

  if (product)
    return (
      <>
        {zoomedPicture && (
          <ZoomedImage
            pictures={product?.pictures.map((pictures) => pictures.split("\n")).flat()}
            zoomedPicture={zoomedPicture}
            setZoomedPicture={setZoomedPicture}
          ></ZoomedImage>
        )}
        <div style={{ display: "flex", padding: "20px 0" }}>
          <div style={{ flexGrow: "1" }}>
            <div style={{ margin: "0 20px" }}>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  height: "430px",
                }}
              >
                {product?.pictures?.length &&
                  product.pictures.map((pictures) => {
                    if (pictures.length === 0) return;
                    pictures = pictures.split("\n");
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          minWidth: "200px",
                          width: "100%",
                        }}
                      >
                        {pictures.map((picture) => (
                          <div
                            onClick={() => setZoomedPicture(picture)}
                            style={{
                              border: "1px solid #eaeaea",
                              height: pictures.length > 1 ? "49%" : "100%",
                              display: "flex",
                              justifyContent: "center",
                              backgroundImage: `url(${HOST}/${picture})`,
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                            }}
                          ></div>
                        ))}
                      </div>
                    );
                  })}
              </div>
              <div style={{ display: "flex", margin: "20px 0" }}>
                <div
                  className="borderTop"
                  onClick={(e) => {
                    e.currentTarget.parentNode.childNodes.forEach((child) =>
                      child.classList.remove("borderTop")
                    );
                    e.currentTarget.classList.add("borderTop");
                    setElement("description");
                  }}
                  style={{
                    width: "33%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                    border: "0.5px solid #e8e8e8",
                    background: "#f7f7f7",
                    textAlign: "center",
                  }}
                >
                  <i
                    className={s.content16}
                    style={{ marginBottom: "5px" }}
                  ></i>
                  <span>Опис</span>
                </div>
                <div
                  onClick={(e) => {
                    e.currentTarget.parentNode.childNodes.forEach((child) =>
                      child.classList.remove("borderTop")
                    );
                    e.currentTarget.classList.add("borderTop");
                    setElement("specs");
                  }}
                  style={{
                    width: "33%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                    border: "0.5px solid #e8e8e8",
                    background: "#f7f7f7",
                    textAlign: "center",
                  }}
                >
                  <i
                    className={s.content17}
                    style={{ marginBottom: "5px" }}
                  ></i>
                  <span>Характеристики</span>
                </div>
                <div
                  onClick={(e) => {
                    e.currentTarget.parentNode.childNodes.forEach((child) =>
                      child.classList.remove("borderTop")
                    );
                    e.currentTarget.classList.add("borderTop");
                    setElement("orderInfo");
                  }}
                  style={{
                    width: "33%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                    border: "0.5px solid #e8e8e8",
                    background: "#f7f7f7",
                    textAlign: "center",
                  }}
                >
                  <i
                    className={s.content18}
                    style={{ marginBottom: "5px" }}
                  ></i>
                  <span>Інформація про замовлення</span>
                </div>
              </div>
              {element === "description" ? (
                <Description
                  data={product?.description}
                  productDescription={true}
                ></Description>
              ) : element === "specs" ? (
                <Specs data={product.specs}></Specs>
              ) : (
                element === "orderInfo" && (
                  <div>
                    {product.orderInfo.split("\n\n").map((block) => {
                      return (
                        <div
                          style={{
                            margin: "10px 0",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {block.split("\n").map((substring) => {
                            const info = substring.split("\t");
                            return (
                              <div>
                                <span style={{ fontWeight: "600" }}>
                                  {info[0] !== undefined ? info[0] : ""}
                                </span>
                                {info[1] !== undefined ? " " + info[1] : " "}
                                <span></span>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                )
              )}
            </div>
          </div>
          <ProductSidebar
            {...product}
            setCart={setCart}
            cart={cart}
            contacts={contacts}
          ></ProductSidebar>
        </div>
      </>
    );
};

const ZoomedImage = ({
  pictures = [],
  zoomedPicture = "",
  setZoomedPicture = () => {},
}) => {
  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    return () =>
      (document.getElementsByTagName("body")[0].style.overflow = "auto");
  }, []);
  if (zoomedPicture === "") return;
  return (
    <div
      style={{
        zIndex: "2",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, .85)",
      }}
    >
      <div
        style={{
          width: "900px",
          height: "600px",
          maxHeight: "80vh",
          minWidth: "fit-content",
          background: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          borderRadius: "10px",
          background: "white",
        }}
      >
        <button
          onClick={() => setZoomedPicture("")}
          style={{
            color: "#a5a5a5",
            boxSizing: "content-box",
            position: "absolute",
            right: "10px",
            top: "15px",
            font: "50px Arial, sans-serif",
            background: "none",
            height: "20px",
            display: "flex",
            alignItems: "center",
            border: "none",
          }}
        >
          ×
        </button>
        <button
          style={{
            fontSize: "60px",
            fontWeight: "300",
            margin: "0 30px",
            background: "none",
            border: "none",
          }}
          onClick={() => {
            const index = pictures.indexOf(zoomedPicture);
            if (index >= 0 && index - 1 >= 0)
              setZoomedPicture(pictures[index - 1]);
          }}
        >
          &#8249;
        </button>
        <div
          style={{
            height: "80%",
            width: "70%",
            backgroundImage: `url(${HOST}/${zoomedPicture})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></div>
        <button
          style={{
            fontSize: "60px",
            fontWeight: "300",
            margin: "0 30px",
            background: "none",
            border: "none",
            
          }}
          onClick={() => {
            const index = pictures.indexOf(zoomedPicture);
            if (index >= 0 && index + 1 < pictures.length)
              setZoomedPicture(pictures[index + 1]);
          }}
        >
          &#8250;
        </button>
      </div>
    </div>
  );
};

export default ProductDetails;
