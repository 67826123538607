import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { SearchResultsAdmin, filterProducts } from "./search_products_admin";
import { MyContext } from "../..";
import { adminAPI, publicAPI } from "../../api/api";
import HOST from "../../env";

const EditProductPreview = () => {
  const [debounceTimer, setDebounceTimer] = useState(null);
  const ref = useRef(null);
  const location = useLocation().pathname.split("/");
  const _id = location[location.length - 1];
  const [product, setProduct] = useState({});
  const [categories, setCategories] = useState({});
  const [filter, setFilter] = useState([]);
  const [file, setFile] = useState();
  const [isDiscount, setIsDiscount] = useState(false);
  const getCategory = async (query = "") => {
    try {
      const res = await publicAPI.getCategory("", query);
      setFilter(res.data.categories.map((category) => category.title));
    } catch (err) {
      console.log(err);
    }
  };
  const getProduct = async () => {
    try {
      const res = await publicAPI.getProducts(_id);
      setProduct(res.data.products[0]);
      setIsDiscount(res.data.products[0]?.discountPrice ? true : false);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getProduct();
  }, []);

  if (product._id === undefined) return;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "20px 0",
      }}
    >
      <div style={{ width: "400px" }}>
        <div style={{ height: "430px", width: "100%", position: "relative" }}>
          <div
            style={{
              height: "100%",
              background: "white",
              backgroundImage: `url('${HOST}/${product.imageURL}')`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "contain",
              filter: "brightness(70%)",
            }}
            ref={ref}
          ></div>
          <div
            onDrop={(e) => {
              {
                e.preventDefault();
                const files = e.dataTransfer.files;
                if (
                  /^image\/(jpeg|png|gif|webp|bmp|svg\+xml)$/.test(
                    files[0]?.type
                  )
                ) {
                  function handleFile(file) {
                    let reader = new FileReader();
                    reader.onload = function (event) {
                      let url = event.target.result;
                      ref.current.style.backgroundImage = `url('${url}')`;
                    };
                    reader.readAsDataURL(file);
                  }
                  handleFile(files[0]);
                  const newFileName = `${Number(new Date())}_${files[0].name}`;
                  const updatedFile = new File([files[0]], newFileName, {
                    type: files[0].type,
                  });
                  setFile(updatedFile);
                }
              }
            }}
            onDragOver={(e) => e.preventDefault()}
            style={{
              border: "2px dashed white",
              position: "absolute",
              top: "0",
              height: "100%",
              width: "100%",
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                color: "white",
                position: "absolute",
                top: "10px",
                fontSize: "18px",
              }}
            >
              Drag & Drop
            </span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            marginTop: "5px",
            position: "relative",
          }}
        >
          <label style={{ fontWeight: "600" }}>Назва:</label>
          <textarea
            onChange={(e) => setProduct({ ...product, title: e.target.value })}
            style={{}}
            value={product.title}
          ></textarea>
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <label style={{ fontWeight: "600" }}>Категорія:</label>
            <input
              onChange={(e) => {
                clearTimeout(debounceTimer);
                setProduct({ ...product, category: { title: e.target.value } });
                if (e.target.value === "") return setFilter([]);
                setDebounceTimer(
                  setTimeout(() => {
                    getCategory(`title=${e.target.value}`);
                  }, 1000)
                );
              }}
              value={product?.category?.title || ""}
            ></input>
            <SearchResultsAdmin
              filter={filter}
              setFilter={setFilter}
              setValue={(text) => {
                setProduct({ ...product, category: { title: text } });
              }}
            ></SearchResultsAdmin>
          </div>
          <label style={{ fontWeight: "600" }}>Код:</label>
          <input
            onChange={(e) => setProduct({ ...product, code: e.target.value })}
            style={{}}
            value={product.code}
          ></input>
          <label style={{ fontWeight: "600" }}>Прапорець:</label>
          <input
            onChange={(e) => setProduct({ ...product, flag: e.target.value })}
            style={{}}
            value={product.flag}
          ></input>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <input
              style={{ width: "15px", height: "15px" }}
              onChange={(e) => {
                setProduct({
                  ...product,
                  isAvailable: !product.isAvailable,
                  toOrder: !product.isAvailable
                    ? { on: false, dayCount: null }
                    : product.toOrder,
                });
              }}
              checked={product.isAvailable}
              type="checkbox"
            ></input>
            <span>В наявності</span>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <input
              style={{ width: "15px", height: "15px" }}
              onChange={(e) => {
                setProduct({
                  ...product,
                  isAvailable: false,
                  toOrder: { on: !product?.toOrder?.on, dayCount: null },
                });
              }}
              checked={product?.toOrder?.on}
              type="checkbox"
            ></input>
            <span>Під замовлення</span>
          </div>
          {product?.toOrder?.on && (
            <>
              <label style={{ fontWeight: "600" }}>Днів:</label>
              <input
                onChange={(e) =>
                  setProduct({
                    ...product,
                    toOrder: {
                      on: product?.toOrder?.on,
                      dayCount: e.target.value,
                    },
                  })
                }
                style={{}}
                value={product?.toOrder?.dayCount}
              ></input>
            </>
          )}
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <input
              style={{ width: "15px", height: "15px" }}
              onChange={(e) => {
                setProduct({ ...product, recommended: !product.recommended });
              }}
              checked={product.recommended}
              type="checkbox"
            ></input>
            <span>Рекомендовано</span>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <input
              style={{ width: "15px", height: "15px" }}
              onChange={(e) => {
                setIsDiscount(!isDiscount);
                if (isDiscount)
                  setProduct({
                    ...product,
                    discountPrice: null,
                    discountPeriod: { start: null, end: null },
                  });
              }}
              checked={isDiscount}
              type="checkbox"
            ></input>
            <span>Знижка</span>
          </div>
          <label style={{ fontWeight: "600" }}>Ціна:</label>
          <input
            onChange={(e) => setProduct({ ...product, price: e.target.value })}
            style={{}}
            value={product.price}
          ></input>
          {isDiscount && (
            <>
              {" "}
              <label style={{ fontWeight: "600" }}>Ціна зі знижкою:</label>
              <input
                onChange={(e) =>
                  setProduct({ ...product, discountPrice: e.target.value })
                }
                value={product?.discountPrice}
              ></input>
              <label style={{ fontWeight: "600" }}>Початок:</label>
              <input
                onChange={(e) =>
                  setProduct({
                    ...product,
                    discountPeriod: {
                      ...product?.discountPeriod,
                      start: e.target.value,
                    },
                  })
                }
                value={product?.discountPeriod?.start}
                type="date"
              ></input>
              <label style={{ fontWeight: "600" }}>Кінець:</label>
              <input
                onChange={(e) =>
                  setProduct({
                    ...product,
                    discountPeriod: {
                      ...product?.discountPeriod,
                      end: e.target.value,
                    },
                  })
                }
                value={product?.discountPeriod?.end}
                type="date"
              ></input>
            </>
          )}
          <div style={{ display: "flex", marginTop: "10px", gap: "10px" }}>
            <button
              onClick={async () => {
                const formData = new FormData();
                formData.append("title", product.title);
                formData.append("imageURL", product.imageURL);
                formData.append("price", product.price);
                formData.append(
                  "discountPrice",
                  product?.discountPrice ? product?.discountPrice : ""
                );
                formData.append(
                  "discountPeriod",
                  JSON.stringify(product?.discountPeriod)
                );
                formData.append("isAvailable", product.isAvailable);
                formData.append("toOrder", JSON.stringify(product.toOrder));
                formData.append("recommended", product.recommended);
                formData.append("code", product.code);
                formData.append("flag", product.flag);
                formData.append("file", file);
                formData.append("category", product?.category?.title || "");
                try {
                  await adminAPI.editProductPreview(_id, formData);
                  getProduct();
                  if (window.history.length > 1) window.history.back();
                  else window.close();
                } catch (err) {
                  console.log(err);
                }
              }}
              style={{
                backgroundColor: "rgb(106, 180, 22)",
                color: "white",
                border: "none",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              Зберегти
            </button>
            <button
              onClick={() => {
                if (window.history.length > 1) window.history.back();
                else window.close();
              }}
              style={{
                backgroundColor: "rgb(106, 180, 22)",
                color: "white",
                border: "none",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              Скасувати
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProductPreview;
