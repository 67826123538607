import React from "react";
import Caption from "./caption";
import s from "../styles/icons.module.css";

const Delivery = ({ data = [] }) => {
  return (
    <div>
      <Caption text={"Умови доставки та оплати"}></Caption>
      {data.map((block, index) => {
        block = block.split("\n\n\n");
        return (
          <div style={{ margin: "30px 0px" }}>
            <span
              className={s["content" + (13 + index)]}
              style={{ color: "#f5643e", fontSize: "18px" }}
            >
              {block[0]}
            </span>
            {block[1].split("\n\n").map((text) => {
              return (
                <div
                  style={{
                    margin: "20px 0",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {text.split("\n").map((substring) => (
                    <span>{substring}</span>
                  ))}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Delivery;
