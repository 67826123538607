import { Route, Routes, useNavigate } from "react-router-dom";
import MenuAdmin from "./components/admin/menu_admin";
import { useEffect } from "react";
import CategoriesAdmin from "./components/admin/categories_admin";
import ProductsAdmin from "./components/admin/products_admin";
import ProductDetailsAdmin from "./components/admin/product_details_admin";
import EditProductPreview from "./components/admin/edit_product_preview";
import { useState } from "react";
import Login from "./components/admin/login";
import { getCookieValue } from "./App";
import { adminAPI } from "./api/api";
import useConditionalNavigate from "./components/custom_hooks/use_conditional_navigate";

const Admin = () => {
  const [filter, setFilter] = useState("");
  const [filterValue, setFilterValue] = useState("true");
  const [isLogged, setIsLogged] = useState(false);
  const validateToken = async () => {
    try {
      await adminAPI.validateToken();
      setIsLogged(true)
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (getCookieValue("token")) validateToken();
    document.getElementsByTagName("body")[0].style.backgroundImage = "none";
    return () => {
      document.getElementsByTagName("body")[0].style.backgroundImage =
        'url("/images/bg.jpg")';
    };
  }, []);
  if (!isLogged) return <Login setIsLogged={setIsLogged}></Login>;
  return (
    <>
      <MenuAdmin setIsLogged={setIsLogged}></MenuAdmin>
      <Routes>
        <Route path="/" element={<Redirect to="products"></Redirect>}></Route>
        <Route
          path="/categories"
          element={<CategoriesAdmin></CategoriesAdmin>}
        ></Route>
        <Route
          path="/subcategories/:any"
          element={<CategoriesAdmin></CategoriesAdmin>}
        ></Route>
        <Route
          path="/products"
          element={
            <ProductsAdmin
              filter={filter}
              filterValue={filterValue}
              setFilter={setFilter}
              setFilterValue={setFilterValue}
            ></ProductsAdmin>
          }
        ></Route>
        <Route
          path="/products/edit/:any"
          element={<EditProductPreview></EditProductPreview>}
        ></Route>
        <Route
          path="/product/:any"
          element={<ProductDetailsAdmin></ProductDetailsAdmin>}
        ></Route>
      </Routes>
    </>
  );
};

const Redirect = ({ to = "products" }) => {
  useRedirect(to);
};

const useRedirect = (to = "products") => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to);
  }, [navigate, to]);
};

export default Admin;
