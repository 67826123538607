import { useEffect, useState } from "react";
import s from "../styles/icons.module.css";

const ButtonControls = ({ containerRef }) => {
  const [disabledRight, setDisabledRight] = useState(false);
  const [disabledLeft, setDisabledlLeft] = useState(true);
  useEffect(() => {
    if (containerRef.current.scrollWidth === containerRef.current.clientWidth) {
      setDisabledRight(true);
    }
  }, [containerRef?.current]);
  return (
    <div
      style={{
        marginRight: "25px",
      }}
    >
      <button
        disabled={disabledLeft}
        className={(disabledLeft ? "" : "text_hover") + " " + s.content10}
        style={{
          padding: "5px",
          borderRadius: "5px",
          border: "0.5px solid #C1C1C1",
          backgroundColor: "#fff",
        }}
        onClick={(e) => {
          const container = containerRef.current;
          setDisabledlLeft(container.scrollLeft - 600 <= 0);
          setDisabledRight(false);
          container.scrollLeft -= 600;
        }}
      >
        Назад
      </button>
      <button
        disabled={disabledRight}
        className={(disabledRight ? "" : "text_hover") + " " + s.content11}
        style={{
          padding: "5px",
          borderRadius: "5px",
          border: "0.5px solid #C1C1C1",
          backgroundColor: "#fff",
        }}
        onClick={(e) => {
          const container = containerRef.current;
          setDisabledRight(
            container.scrollLeft + 600 + container.offsetWidth >
              container.scrollWidth
          );
          setDisabledlLeft(false);
          container.scrollLeft += 600;
        }}
      >
        Вперед
      </button>
    </div>
  );
};

export default ButtonControls;
