import React from "react";
import { useNavigate } from "react-router-dom";
import useConditionalNavigate from "./custom_hooks/use_conditional_navigate";

const Header = ({ cart = [], setCartOn = () => {} }) => {
  const navigate = useConditionalNavigate()
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#333",
      }}
    >
      <header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          height: "28px",
          color: "#fff",
          minWidth: "1320px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          <div
            onClick={(e) => navigate("/", e)}
            style={{ height: "100%", display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <img
              style={{ height: "100%" }}
              src="/images/4532869852_w350_h100_coffee-ride.webp"
            ></img>
            <span
              style={{
                fontSize: "14px",
                marginLeft: "5px",
                height: "fit-content",
              }}
            >
              Coffee Ride
            </span>
          </div>
          {/* <div>
            <span
              style={{
                color: "#fff",
                textDecoration: "none",
                padding: "0 8px",
              }}
              href=""
            >
              UA
            </span>
            <span
              style={{
                color: "#fff",
                textDecoration: "none",
                borderLeft: "1px solid #ffffff",
                padding: "0 8px",
              }}
              href=""
            >
              EN
            </span>
          </div> */}
          {/* <a style={{ height: "21px", padding: "0 8px" }}>
          <img src="/images/logo-small-white-hc8838ea476b97e8179c0578788a9c93bf.png"></img>
        </a>
        <div
          style={{
            padding: "2px",
            backgroundColor: "#fff",
            height: "fit-content",
            borderRadius: "3px"
          }}
        >
          <div
            style={{
              backgroundImage: "url(/images/site-hc059eebc86aad2b4f3c766687fc39306f.png)",
              backgroundPosition: "-155px -179px",
              width: "21px",
              height: "12px",
              borderRadius: "3px"
            }}
          ></div>
        </div>
        <a style={{ height: "21px", padding: "0 8px" }}>
          <img src="/images/bigl-company-site-header-white-hc82ab4d8dfbcb07138a6e0b5e03fd8d18.png"></img>
        </a> */}
        </div>
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          <div
            style={{ display: "flex", alignItems: "center", padding: "0 8px" }}
          >
            <div
              style={{
                backgroundPosition: "-199px -237px",
                width: "13px",
                height: "17px",
              }}
            ></div>
            {/* <a style={{ padding: "0 8px", textDecoration: "underline" }}>
              20 відгуків
            </a> */}
          </div>
          <div
            onClick={() => setCartOn(true)}
            style={{
              backgroundColor: "#4b4b4b",
              padding: "0 32px",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundImage:
                  'url("/images/site-hc059eebc86aad2b4f3c766687fc39306f.png")',
                backgroundPosition: "-212px -237px",
                width: "14px",
                height: "14px",
              }}
            ></div>
            <div style={{ display: "flex" }}>
              <span style={{ padding: "0 8px" }}>Кошик</span>
              {cart.length > 0 && (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "12px",
                    fontWeight: "600",
                    height: "18px",
                    width: "18px",
                    background: "#66c571",
                    borderRadius: "50%",
                  }}
                >
                  {cart.length}
                </span>
              )}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
