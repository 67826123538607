import React, { useState } from "react";
import { publicAPI } from "../api/api";

const GetContacts = ({
  isOpen = true,
  cart = [],
  setIsOpen = () => {},
  setCartOn = () => {},
  setCart = () => {},
}) => {
  const [resultText, setResultText] = useState("");
  const [isComment, setIsComment] = useState(false);
  const [comment, setComment] = useState("");
  return (
    <div style={{ textAlign: "center" }}>
      {isOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "25px",
              borderRadius: "8px",
              width: "300px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              position: "relative",
            }}
          >
            <svg
              onClick={() => {
                resultText === "" ? setIsOpen(false) : setCartOn(false);
              }}
              style={{
                borderRadius: "8px",
                outline: "none",
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "none",
                border: "none",
                fontSize: "20px",
                cursor: "pointer",
              }}
              width="24px"
              height="24px"
              fill="none"
              viewBox="0 0 24 24"
              data-testid="SvgClose"
              data-qaid="SvgClose"
              class="_2uocW LoRyP"
              focusable="false"
              aria-hidden="true"
              tabindex="-1"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M4.414 3A1 1 0 0 0 3 4.414L10.586 12 3 19.586A1 1 0 1 0 4.414 21L12 13.414 19.586 21A1 1 0 0 0 21 19.586L13.414 12 21 4.414A1 1 0 0 0 19.586 3L12 10.586 4.414 3Z"
                clip-rule="evenodd"
              ></path>
            </svg>
            {resultText === "" ? (
              <h2
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                }}
              >
                Введіть контактні дані
              </h2>
            ) : (
              <div>
                {resultText.split("\n").map((text) => {
                  return (
                    <>
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                        }}
                      >
                        {text}
                      </span>
                      <br></br>
                    </>
                  );
                })}
              </div>
            )}

            {resultText === "" && (
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  const name = e.currentTarget[0];
                  const phone = e.currentTarget[1];
                  const settlement = e.currentTarget[3];
                  const department = e.currentTarget[4];
                  let invalidInput = false;
                  if (name.value === "") {
                    name.placeholder = "Обов'язкове поле";
                    name.style.borderColor = "red";
                    invalidInput = true;
                  }
                  if (phone.value === "") {
                    phone.placeholder = "Обов'язкове поле";
                    phone.style.borderColor = "red";
                    invalidInput = true;
                  }
                  if (settlement.value === "") {
                    settlement.placeholder = "Обов'язкове поле";
                    settlement.style.borderColor = "red";
                    invalidInput = true;
                  }
                  if (department.value === "") {
                    department.placeholder = "Обов'язкове поле";
                    department.style.borderColor = "red";
                    invalidInput = true;
                  }
                  if (invalidInput) return;
                  const data = {
                    name: name.value,
                    phone: phone.value,
                    postalService: e.currentTarget[2].value,
                    settlement: settlement.value,
                    department: department.value,
                    paymentType: e.currentTarget[5].value,
                    comment: e.currentTarget[6].value
                  };
                  try {
                    if (e.currentTarget[5].value === "now") {
                      const response = await publicAPI.paymentInit(
                        cart,
                        data,
                        "checkout=true"
                      );
                      setCart([]);
                      if (response?.data?.url)
                        window.open(response?.data?.url, "_self");
                    } else if (
                      e.currentTarget[5].value === "later" ||
                      e.currentTarget[5].value === "fop"
                    ) {
                      await publicAPI.paymentInit(cart, data, "checkout=false");
                      setCart([]);
                      setResultText("Дякуємо за покупку!\nОчікуйте на дзвінок");
                    }
                  } catch (err) {
                    setResultText("Упс, щось пішло не так :(");
                    console.log(err);
                  }
                }}
                style={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <input
                  onFocus={(e) => {
                    e.target.style.borderColor = "#ccc";
                    e.target.placeholder = "ПІБ";
                  }}
                  type="text"
                  placeholder="ПІБ"
                  style={{
                    padding: "10px",
                    fontSize: "16px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
                <input
                  onFocus={(e) => {
                    e.target.style.borderColor = "#ccc";
                    e.target.placeholder = "Телефон";
                  }}
                  type="text"
                  placeholder="Телефон"
                  style={{
                    padding: "10px",
                    fontSize: "16px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
                <select
                  style={{
                    padding: "10px",
                    fontSize: "16px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                >
                  <option value="Нова пошта">Нова пошта</option>
                </select>
                <input
                  onFocus={(e) => {
                    e.target.style.borderColor = "#ccc";
                    e.target.placeholder = "Населений пункт";
                  }}
                  type="text"
                  placeholder="Населений пункт"
                  style={{
                    padding: "10px",
                    fontSize: "16px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
                <input
                  onFocus={(e) => {
                    e.target.style.borderColor = "#ccc";
                    e.target.placeholder = "Відділення";
                  }}
                  type="text"
                  placeholder="Відділення"
                  style={{
                    padding: "10px",
                    fontSize: "16px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
                <select
                  style={{
                    padding: "10px",
                    fontSize: "16px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                >
                  <option value="now">Оплата онлайн</option>
                  <option value="later">Оплата при отриманні</option>
                  <option value="fop">Оплата на рахунок ФОП</option>
                </select>
                {!isComment && (
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setIsComment(true);
                    }}
                  >
                    Додати коментар
                  </a>
                )}
                {isComment && (
                  <textarea
                    onFocus={(e) => {
                      e.target.style.borderColor = "#ccc";
                      e.target.placeholder = "Введіть коментар";
                    }}
                    type="text"
                    placeholder="Введіть коментар"
                    style={{
                      padding: "10px",
                      fontSize: "16px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                  />
                )}
                <button
                  type="submit"
                  style={{
                    background: "#69b316",
                    color: "white",
                    border: "none",
                    width: "100%",
                    padding: "12px 0",
                    borderRadius: "8px",
                    fontWeight: "500",
                  }}
                >
                  Оформити Замовлення
                </button>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GetContacts;
