import { deleteCookie } from "../../App";
import s2 from "../../styles/animations.module.css";
import { useNavigate } from "react-router-dom";
import useConditionalNavigate from "../custom_hooks/use_conditional_navigate";
import { adminAPI } from "../../api/api";

const MenuAdmin = ({ setIsLogged = () => {} }) => {
  const navigate = useConditionalNavigate();
  return (
    <div
      style={{
        color: "#fff",
        backgroundColor: "#333",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "40px",
      }}
    >
      <div
        className={s2.parent}
        style={{
          listStyleType: "none",
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <span style={{ borderRight: "1px dotted #fff" }}>
          <a
            className={s2.menu}
            style={{ padding: "0 20px" }}
            onClick={(e) => navigate("/administrator/categories", e)}
          >
            Категорії
          </a>
        </span>

        <span style={{ borderRight: "1px dotted #fff" }}>
          <a
            className={s2.menu}
            style={{ padding: "0 20px" }}
            onClick={(e) => navigate("/administrator/products", e)}
          >
            Товари
          </a>
        </span>
        <span style={{ borderRight: "1px dotted #fff" }}>
          <a
            className={s2.menu}
            style={{ padding: "0 20px" }}
            onClick={async () => {
              try {
                const response = await adminAPI.exportExcel();
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "exported_data.xlsx");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              } catch (error) {
                console.error("Помилка під час експорту:", error);
              }
            }}
          >
            Експорт Excel
          </a>
        </span>
      </div>
      <div
        style={{
          margin: "0 20px",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          onClick={() => {
            deleteCookie("token");
            setIsLogged(false);
          }}
          className={s2.menu}
          style={{ cursor: "pointer" }}
        >
          Logout
        </span>
      </div>
    </div>
  );
};

export default MenuAdmin;
