import Caption from "./caption";
import s from "../styles/icons.module.css";

const Contacts2 = ({ data = "" }) => {
  return (
    <div style={{ marginBottom: "30px" }}>
      <Caption text={"Контакти"} margin="0 0 10px 0"></Caption>
      {data.split("\n\n").map((block, index) => {
        block = block.split("\n");
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              borderTop: index !== 0 ? "1px solid #C1C1C1" : "none",
            }}
          >
            <span style={{ width: "300px", padding: "10px" }}>{block[0]}</span>

            <span
              className={
                /Сайт/.test(block[0]) || /Email/.test(block[0]) ? s.content12 : ""
              }
              style={{
                fontStyle: /Адреса/.test(block[0]) ? "italic" : "inherit",
                color:
                  /Сайт/.test(block[0]) || /Email/.test(block[0])
                    ? "#f5643e"
                    : "inherit",
              }}
            >
              {/Телефони/.test(block[0])
                ? block[1].slice(0, 4) +
                  " (" +
                  block[1].slice(4, 6) +
                  ") " +
                  block[1].slice(6, 9) +
                  "-" +
                  block[1].slice(9, 11) +
                  "-" +
                  block[1].slice(11, 13)
                : block[1]}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default Contacts2;
