import { useEffect, useRef, useState } from "react";
import ButtonControls from "./button_controls";
import Caption from "./caption";
import HOST from "../env";
import s from "../styles/animations.module.css";
import { publicAPI } from "../api/api";
import { useNavigate } from "react-router-dom";
import useConditionalNavigate from "./custom_hooks/use_conditional_navigate";

const Reccomendations = ({ captionText = "" }) => {
  const [products, setProducts] = useState([]);
  const getProducts = async (query = "") => {
    try {
      const res = await publicAPI.getProducts("", `${query}`);
      setProducts(res.data.products);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (captionText === "Ми рекомендуємо")
      getProducts(`filter=recommended&filterValue=true`);
    else if (captionText === "Ви переглядали") {
      const viewedProducts =
        JSON.parse(localStorage.getItem("viewedProducts") || null) || [];
      viewedProducts.length !== 0 &&
        getProducts(
          `filter=_ids&filterValue=${viewedProducts.map(
            (product) => product._id
          )}`
        );
    }
  }, []);
  const containerRef = useRef(null);
  if (products.length === 0) return;
  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Caption margin="0" text={captionText}></Caption>
        <ButtonControls containerRef={containerRef}></ButtonControls>
      </div>
      <div
        className="scrollblock"
        style={{
          width: "100%",
          display: "flex",
          gap: "10px",
          overflowX: "scroll",
          scrollBehavior: "smooth",
          position: "relative",
          paddingBottom: "20px",
          margin: "20px 0",
        }}
        ref={containerRef}
      >
        {products.map((item) => (
          <Recomendation {...item}></Recomendation>
        ))}
      </div>
    </div>
  );
};

const Recomendation = ({ _id = "", title = "", imageURL = "", price = "" }) => {
  const navigate = useConditionalNavigate();
  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        minWidth: "200px",
        width: "200px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "10px",
      }}
    >
      <div
        className={s.vertigo}
        style={{
          flexGrow: "1",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          onClick={(e) => navigate("/product/" + _id, e)}
          style={{
            maxHeight: "150px",
            maxWidth: "100%",
          }}
          src={`${HOST}/${imageURL}`}
        ></img>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <span
          onClick={(e) => navigate("/product/" + _id, e)}
          className="text_hover"
        >
          {title}
        </span>
        <span
          style={{
            padding: "7px",
            backgroundColor: "#FFF3B5",
            borderRadius: "5px",
            width: "fit-content",
          }}
        >
          {price + " ₴"}
        </span>
      </div>
    </div>
  );
};

export default Reccomendations;
