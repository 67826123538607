const Sort = ({ setSort = () => {}, setOrder = () => {} }) => {
  return (
    <div>
      <span>Сортування: </span>
      <select
        onChange={(e) => {
          const value = e.target.value.split("_");
          setSort(value[0]);
          setOrder(value[1]);
        }}
        style={{
          borderRadius: "50px",
          padding: "5px",
          fontSize: "14px",
          border: "1px solid #e8e8e8",
          outline: "none",
          boxShadow: "0 0px 2px rgba(0, 0, 0, 0.1)",
        }}
      >
        <option
          value={"title_1"}
          style={{ fontSize: "14px" }}
        >
          за порядком
        </option>
        <option
          value={"price_1"}
          style={{ fontSize: "14px" }}
        >
          за зростанням ціни
        </option>
        <option
          value={"price_-1"}
          style={{ fontSize: "14px" }}
        >
          за зниженням ціни
        </option>
      </select>
    </div>
  );
};

export default Sort;
