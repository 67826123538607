import React, { useState, useEffect } from 'react';
import s2 from "../styles/animations.module.css";
const images = ["/images/4536107641_4536107641.webp", "/images/4546172358_4546172358.webp" ];

const ImageSlider = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={s2.image_slider}>
      {images.map((image, idx) => (
        <img
          key={idx}
          src={image}
          style={idx === index ? {opacity: "1"} : {opacity: "0"}}
        />
      ))}
    </div>
  );
};

export default ImageSlider;

