import React, { useState } from "react";

const PORTION_SIZE = 3;

let Pagination = ({
  totalCount = 0,
  pageSize,
  currentPage,
  setCurrentPage = () => {},
}) => {
  let pagesCount = Math.ceil(totalCount / pageSize);
  let portionsCount = Math.ceil(totalCount / (pageSize * PORTION_SIZE));
  let [currentPortionNumber, setCurrentPortion] = useState(1);
  let portionsLeftBorder = (currentPortionNumber - 1) * PORTION_SIZE + 1;
  let portionsRightBorder = currentPortionNumber * PORTION_SIZE;

  let pages = [];
  for (let i = 1; i <= pagesCount; i++) {
    pages.push(i);
  }
  if (totalCount === 0) return;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        height: "fit-content",
      }}
    >
      {currentPortionNumber > 1 && (
        <>
          {" "}
          <button
            style={{
              color: "#e75539",
              padding: "5px 10px",
              background: "none",
              border: "1px solid #e8e8e8",
              borderRadius: "5px 0 0 5px",
              cursor: "pointer",
            }}
            onClick={() => {
              setCurrentPortion(currentPortionNumber - 1);
            }}
          >
            ←
          </button>
          <span
            style={{
              color: currentPage === 1 ? "#e75539" : "black",
              padding: "5px 10px",
              border: "1px solid #e8e8e8",
              cursor: "pointer",
            }}
            onClick={(e) => {
              setCurrentPage(1);
            }}
          >
            1
          </span>
          <span
            style={{
              color: "black",
              padding: "5px 10px",
              border: "1px solid #e8e8e8",
              cursor: "pointer",
            }}
          >
            ...
          </span>
        </>
      )}
      {pages
        .filter((p) => p >= portionsLeftBorder && p <= portionsRightBorder)
        .map((p) => {
          return (
            <span
              style={{
                color: currentPage === p ? "#e75539" : "black",
                padding: "5px 10px",
                border: "1px solid #e8e8e8",
                cursor: "pointer",
              }}
              onClick={(e) => {
                setCurrentPage(p);
              }}
            >
              {p}{" "}
            </span>
          );
        })}

      {currentPortionNumber !== portionsCount && (
        <>
          <span
            style={{
              color: "black",
              padding: "5px 10px",
              border: "1px solid #e8e8e8",
              cursor: "pointer",
            }}
          >
            ...
          </span>
          <span
            style={{
              color:
                currentPage === pages[pages.length - 1] ? "#e75539" : "black",
              padding: "5px 10px",
              border: "1px solid #e8e8e8",
              cursor: "pointer",
            }}
            onClick={(e) => {
              setCurrentPage(pages[pages.length - 1]);
            }}
          >
            {pages[pages.length - 1]}
          </span>
          <button
            style={{
              color: "#e75539",
              padding: "5px 10px",
              background: "none",
              border: "1px solid #e8e8e8",
              borderRadius: "0 5px 5px 0",
              cursor: "pointer",
            }}
            onClick={() => {
              setCurrentPortion(currentPortionNumber + 1);
            }}
          >
            →
          </button>
        </>
      )}
    </div>
  );
};

export default Pagination;
