import { useEffect } from "react";

const Caption = ({
  setFilterOn = () => {},
  text,
  paddingLeft = "0",
  margin = "0",
}) => {
  useEffect(() => {
    setFilterOn(true);
    return () => setFilterOn(false)
  }, [text]);
  return (
    <div style={{ margin, paddingLeft }}>
      <span style={{ fontSize: "20px" }}>
        <span style={{ color: "#69B316", fontSize: "20px" }}>{text[0]}</span>
        {text.slice(1)}
      </span>
    </div>
  );
};

export default Caption;
