import s from "../../styles/overlay.module.css";
const SpecsAdmin = ({ data = "", setEditOn = () => {} }) => {
  if (data === "") {
    return (
      <div
        className={s.container}
        style={{ position: "relative", height: "30px" }}
      >
        <div
          className={s.child}
          style={{ background: "white", height: "100%" }}
        ></div>
        <div
          style={{
            opacity: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            position: "absolute",
            top: "0",
            zIndex: "2",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
          }}
        >
          <button
            onClick={() => setEditOn(true)}
            style={{
              background: "none",
              height: "fit-content",
              fontSize: "16px",
              fontWeight: "600",
              backgroundColor: "transparent",
              outline: "none",
              cursor: "pointer",
              color: "white",
              border: "2px solid white",
              borderRadius: "10px",
              padding: "8px",
            }}
          >
            Add
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className={s.container} style={{ position: "relative" }}>
      <table
        className={s.child}
        style={{ borderSpacing: "0", width: "100%", background: "white" }}
      >
        <tbody>
          {data.split("\n\n").map((block) => (
            <>
              {block.split("\n").map((text, index) => {
                if (index === 0)
                  return (
                    <tr>
                      <th
                        style={{
                          background: "#f6f6f6",
                          padding: "5px",
                          textAlign: "left",
                          border: "1px #e5e5e5 solid",
                          fontWeight: "600",
                        }}
                        colSpan="2"
                      >
                        {text}
                      </th>
                    </tr>
                  );
                else
                  return (
                    <tr key={index} style={{ padding: "5px" }}>
                      {text.split("\t").map((column, index) => (
                        <td
                          key={index}
                          style={{
                            padding: "5px",
                            border: "1px #e5e5e5 solid",
                            width: "50%",
                          }}
                        >
                          {column}
                        </td>
                      ))}
                    </tr>
                  );
              })}
            </>
          ))}
        </tbody>
      </table>
      <div
        style={{
          opacity: "0",
          width: "100%",
          height: "100%",
          display: "flex",
          position: "absolute",
          top: "0",
          zIndex: "2",
          alignItems: "center",
          justifyContent: "center",
          boxSizing: "border-box",
        }}
      >
        <button
          onClick={() => setEditOn(true)}
          style={{
            background: "none",
            height: "fit-content",
            fontSize: "16px",
            fontWeight: "600",
            backgroundColor: "transparent",
            outline: "none",
            cursor: "pointer",
            color: "white",
            border: "2px solid white",
            borderRadius: "10px",
            padding: "8px",
          }}
        >
          Edit
        </button>
      </div>
    </div>
  );
};

export default SpecsAdmin;
