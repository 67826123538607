const Specs = ({ data = "" }) => {
  return (
    <table style={{ borderSpacing: "0", width: "100%" }}>
      <tbody>
      {data.split("\n\n").map((block) => (
            <>
              {block.split("\n").map((text, index) => {
                if (index === 0)
                  return (
                    <tr>
                      <th
                        style={{
                          background: "#f6f6f6",
                          padding: "5px",
                          textAlign: "left",
                          border: "1px #e5e5e5 solid",
                          fontWeight: "600",
                        }}
                        colSpan="2"
                      >
                        {text}
                      </th>
                    </tr>
                  );
                else
                  return (
                    <tr key={index} style={{ padding: "5px" }}>
                      {text.split("\t").map((column, index) => (
                        <td
                          key={index}
                          style={{
                            padding: "5px",
                            border: "1px #e5e5e5 solid",
                            width: "50%",
                          }}
                        >
                          {column}
                        </td>
                      ))}
                    </tr>
                  );
              })}
            </>
          ))}
      </tbody>
    </table>
  );
};

export default Specs;
