import s from "../styles/icons.module.css";
import s2 from "../styles/animations.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { publicAPI } from "../api/api";
import useConditionalNavigate from "./custom_hooks/use_conditional_navigate";

const Menu = () => {
  const navigate = useConditionalNavigate();
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const getCategory = async () => {
      try {
        const res = await publicAPI.getCategory();
        setCategories(res.data.categories);
      } catch (err) {
        console.log(err);
      }
    };
    getCategory();
  }, []);
  return (
    <div
      style={{
        color: "#fff",
        backgroundColor: "#333",
        marginTop: "20px",
      }}
    >
      <ul
        className={s2.parent}
        style={{
          listStyleType: "none",
          display: "flex",
          alignItems: "center",
          height: "40px",
        }}
      >
        <li style={{ borderRight: "1px dotted #fff" }}>
          <a
            className={s2.menu}
            style={{ padding: "0 20px" }}
            onClick={(e) => navigate("/", e)}
          >
            Головна
          </a>
        </li>
        <li
          id={s2.hidden}
          className={s.content7}
          style={{ borderRight: "1px dotted #fff" }}
        >
          <a
            className={s2.menu}
            style={{ padding: "0 0 0 20px" }}
            onClick={(e) => navigate("/", e)}
          >
            Товари та послуги
          </a>
          <ul
            style={{
              paddingTop: "5px",
              listStyleType: "none",
              backgroundColor: "#333",
              position: "absolute",
              zIndex: "2",
              width: "290px",
              transition: "all 1s",
            }}
          >
            {categories.map((category) => (
              <li
                className={s2.submenu}
                style={{
                  borderBottom: "1px dotted #4e4e4e",
                }}
                onClick={(e) => navigate("/categories/" + category._id, e)}
              >
                <a>{category.title}</a>
              </li>
            ))}
          </ul>
        </li>
        <li
          className
          style={{
            borderRight: "1px dotted #fff",
            borderLeft: "1px dotted #fff",
          }}
        >
          <a
            className={s2.menu}
            onClick={(e) => navigate("/aboutus", e)}
            style={{ padding: "0 20px" }}
          >
            Про нас
          </a>
        </li>
        <li style={{ borderRight: "1px dotted #fff" }}>
          <a
            className={s2.menu}
            style={{ padding: "0 20px" }}
            onClick={(e) => navigate("/contacts", e)}
          >
            Контакти
          </a>
        </li>
        <li style={{ borderRight: "1px dotted #fff" }}>
          <a
            className={s2.menu}
            style={{ padding: "0 20px" }}
            onClick={(e) => navigate("/delivery", e)}
          >
            Доставка та оплата
          </a>
        </li>
        <li>
          <a
            className={s2.menu}
            style={{ padding: "0 20px" }}
            onClick={(e) => navigate("/return", e)}
          >
            Повернення та обмін
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
