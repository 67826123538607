import React from "react";
import Caption from "./caption";

const Description = ({
  data = "",
  captionText = "",
  productDescription = false,
}) => {
  return (
    <div>
      {captionText && !productDescription && (
        <Caption text={captionText}></Caption>
      )}
     {data.split("\n\n").map((text, index) => {
          return (
            <div
              style={{
                margin: data ? "20px 0" : "0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {text.split("\n").map((substring) => (
                <span
                  style={{
                    fontWeight:
                      productDescription && index === 0 ? "600" : "400",
                  }}
                >
                  {substring}
                </span>
              ))}
            </div>
          );
        })}
    </div>
  );
};

export default Description;
