import React from "react";

const Aboutus = ({ data = { description: "", companyInfo: "" } }) => {
  if (!data.description && !data.companyInfo) return;
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        {data.description && data.description.split("\n\n").map((block) => {
          block = block.split("\b").filter((text) => text !== "");
          return (
            <div>
              <span style={{ fontWeight: 600 }}>
                {block.length > 1 ? block[0] : ""}
              </span>
              <span>{block.length > 1 ? block[1] : block[0]}</span>
            </div>
          );
        })}
      </div>
      {data.companyInfo && data.companyInfo.split("\n\n\n").map((block) => {
        block = block.split("\n\n");
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "30px 0",
            }}
          >
            <h2
              style={{
                fontWeight: 600,
                marginBottom: "25px",
                fontSize: "18px",
              }}
            >
              {block[0]}
            </h2>
            {block[1].split("\n").map((block) => {
              block = block.split("\t")
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{ fontWeight: 600, width: "300px", padding: "5px" }}
                  >
                    {block[0]}
                  </span>
                  <span>{block[1]}</span>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default Aboutus;
