import Flag from "./flag";
import s from "../styles/icons.module.css";

const ProductSidebar = ({
  _id = "",
  title = "",
  imageURL = "",
  price = "",
  discountPrice = "",
  discountPeriod = { start: null, end: null },
  isAvailable = "",
  toOrder = { on: false, dayCount: null },
  code = "",
  flag = "",
  cart = [],
  setCart = () => {},
  contacts = "",
}) => {
  const isPeriodValid = () => {
    const startNumber = new Date(discountPeriod?.start).getTime();
    const endNumber = new Date(discountPeriod?.end).getTime();
    const nowNumber = new Date().getTime();
    return nowNumber < endNumber && endNumber - startNumber > 0;
  };
  let phone = "";
  if (contacts) {
    contacts.split("\n\n").map((contact) => {
      contact = contact.split("\n");
      if (contact[0] === "Телефони") phone = contact[1];
    });
  }
  return (
    <div style={{ minWidth: "330px", width: "330px" }}>
      <h1
        style={{
          marginBottom: "30px",
          fontSize: "20px",
          fontWeight: "700",
          color: "#444",
        }}
      >
        {title}
      </h1>
      <div
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          background: "#faf8f7",
          borderRadius: "4px",
        }}
      >
        {flag && <Flag text={flag}></Flag>}
        <div>
          {isAvailable ? (
            <span style={{ fontSize: "16px", color: "#6AB416" }}>
              В наявності
            </span>
          ) : toOrder?.on && toOrder?.dayCount ? (
            <span style={{ fontSize: "16px", color: "#ACACAC" }}>
              Під замовлення ({toOrder?.dayCount} днів)
            </span>
          ) : (
            <span style={{ fontSize: "16px", color: "#E75539" }}>
              Немає в наявності
            </span>
          )}
        </div>
        <div>
          <span
            style={{
              fontStyle: "italic",
              color: "#acacac",
              fontSize: "12px",
              marginTop: "10px",
            }}
          >
            {code && `Код: ${code}`}
          </span>
        </div>
        {/* <div style={{ display: "flex", alignItems: "center" }}>
          <img
            style={{ marginRight: "5px" }}
            src="/images/clock-hce485ee02d683536abbd38e8ccc844b28.png"
          ></img>
          <span style={{ verticalAlign: "middle" }}>
            Відправка з ... 2024
          </span>
        </div> */}
        {(!discountPrice || !isPeriodValid()) && (
          <span
            style={{
              padding: "7px",
              backgroundColor: "#FFF3B5",
              borderRadius: "5px",
              width: "fit-content",
            }}
          >
            {price + " ₴"}
          </span>
        )}
        {discountPrice && isPeriodValid() && (
          <>
            <span style={{ color: "red" }}>
              {discountPeriod.start + " - " + discountPeriod.end}
            </span>
            <div
              style={{
                width: "fit-content",
                backgroundColor: "#FFF3B5",
                borderRadius: "5px",
              }}
            >
              <span
                style={{
                  width: "fit-content",
                  padding: "7px",
                  fontSize: "20px",
                  position: "relative",
                  display: "inline-block",
                  color: "black",
                }}
              >
                {price}
                <span
                  style={{
                    content: '""',
                    position: "absolute",
                    top: "50%",
                    left: "-10%",
                    width: "120%",
                    height: "2px",
                    background: "red",
                    transform: "rotate(45deg)",
                    transformOrigin: "center",
                  }}
                ></span>
              </span>
              <span
                style={{
                  width: "fit-content",
                  padding: "7px",
                  fontSize: "20px",
                }}
              >
                {discountPrice + " ₴"}
              </span>
            </div>
          </>
        )}
        {isAvailable || (toOrder?.on && toOrder.dayCount) ? (
          cart.filter((product) => product.title === title).length > 0 ? (
            <button
              disabled={true}
              style={{
                height: "35px",
                width: "100%",
                backgroundColor: "rgb(172, 172, 172)",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
              }}
            >
              В корзині
            </button>
          ) : (
            <button
              onClick={() => {
                let index;
                cart.find((product, i) => {
                  if (product.title === title) index = i;
                });
                const newCart = [...cart];
                if (index !== undefined) {
                  newCart[index].count += 1;
                  setCart(newCart);
                } else {
                  newCart.push({
                    _id,
                    title,
                    imageURL,
                    isAvailable,
                    price,
                    discountPrice,
                    discountPeriod,
                    count: 1,
                  });
                  setCart(newCart);
                }
              }}
              style={{
                height: "35px",
                width: "100%",
                background: "#69b316",
                borderRadius: "4px",
                border: "none",
                color: "#fff",
              }}
            >
              Купити
            </button>
          )
        ) : (
          <></>
        )}
        <div>
          <span className={s.content9}>
            {phone.slice(0, 4) +
              " (" +
              phone.slice(4, 6) +
              ") " +
              phone.slice(6, 9) +
              "-" +
              phone.slice(9, 11) +
              "-" +
              phone.slice(11, 13)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductSidebar;
