import React from "react";
import s from "../styles/schedule_table.module.css"

const ScheduleModal = ({ data = [], setModalOn }) => {
  return (
    <div
      style={{
        zIndex: "2",
        width: "100%",
        height: "100%",
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, .85)",
      }}
    >
      <div
        style={{
          padding: "30px",
          backgroundColor: "#f8f8f8",
          position: "relative",
        }}
      >
        <button
          style={{
            color: "#a5a5a5",
            boxSizing: "content-box",
            padding: "8px",
            position: "absolute",
            right: "0",
            top: "0",
            font: "30px Arial, sans-serif",
            background: "none",
            height: "20px",
            display: "flex",
            alignItems: "center",
            border: "none",
          }}
          onClick={() => {
            setModalOn(false);
            document.getElementsByTagName("body")[0].style.overflow = "auto";
          }}
        >
          ×
        </button>
        <div>
          <table className={s.table}>
            <tbody>
              <tr>
                <th className={s.th}>День</th>
                <th className={s.th}>Години<br></br>роботи</th>
                <th className={s.th}>Перерва</th>
              </tr>

              <tr>
                <td className={s.td}d>Понеділок</td>
                <td className={s.td}>{data?.Monday}</td>
                <td className={s.td}></td>
              </tr>

              <tr>
                <td className={s.td}>Вівторок</td>
                <td className={s.td}>{data?.Tuesday}</td>
                <td className={s.td}></td>
              </tr>

              <tr>
                <td className={s.td}>Середа</td>
                <td className={s.td}>{data?.Wednesday}</td>
                <td className={s.td}></td>
              </tr>

              <tr>
                <td className={s.td}>Четвер</td>
                <td className={s.td}>{data?.Thursday}</td>
                <td className={s.td}></td>
              </tr>

              <tr>
                <td className={s.td}>Пʼятниця</td>
                <td className={s.td}>{data?.Friday}</td>
                <td className={s.td}></td>
              </tr>

              <tr>
                <td className={s.td}>Субота</td>
                <td className={s.td}>{data?.Saturday}</td>
                <td className={s.td}></td>
              </tr>

              <tr>
                <td className={s.td}>Неділя</td>
                <td className={s.td}>{data?.Sunday}</td>
                <td className={s.td}></td>
              </tr>
            </tbody>
          </table>
          <p style={{color: "#757575", fontSize: "12px", lineHeight: "1.5", marginTop: "10px"}}>* Час вказано для регіону: Україна, Київ</p>
        </div>
      </div>
    </div>
  );
};

export default ScheduleModal;
