const Contacts = ({ data = "" }) => {
  return (
    <div
      style={{
        paddingBottom: "50px",
        backgroundClip: "padding-box",
        background: "#fff",
      }}
    >
      <div style={{ padding: "5px 20px", backgroundColor: "#333" }}>
        <h6 style={{ color: "#fff" }}>
          <span style={{ color: "#69B316" }}>К</span>онтакти
        </h6>
      </div>

      <div
        style={{
          padding: "10px 20px",
          border: "0.5px solid #C1C1C1",
        }}
      >
        {data.split("\n\n").map((block, index) => {
          block = block.split("\n");
          return (
            <div
              style={
                index !== 0
                  ? {
                      paddingTop: "10px",
                      marginTop: "10px",
                      borderTop: "1px dashed #C1C1C1",
                    }
                  : {}
              }
            >
              <h6 style={{ marginBottom: "5px", fontWeight: "600" }}>
                {block[0]}
              </h6>
              <p
                style={{
                  fontStyle: /Адреса/.test(block[0]) ? "italic" : "inherit",
                  color:
                    /Сайт/.test(block[0]) || /Email/.test(block[0])
                      ? "#f5643e"
                      : "inherit",
                }}
              >
                {/Телефони/.test(block[0])
                  ? block[1].slice(0, 4) +
                    " (" +
                    block[1].slice(4, 6) +
                    ") " +
                    block[1].slice(6, 9) +
                    "-" +
                    block[1].slice(9, 11) +
                    "-" +
                    block[1].slice(11, 13)
                  : block[1]}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Contacts;
