import React from "react";

const Footer = () => {
  return (
    <footer
      style={{
        color: "#333",
        backgroundColor: "#ddd",
        height: "75px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "3px",
        textDecoration: "underline"
      }}
    >
    </footer>
  );
};

export default Footer;
