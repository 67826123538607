import React, { useEffect, useState } from "react";
import s2 from "../styles/animations.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import Description from "./description";
import { publicAPI } from "../api/api";
import HOST from "../env";
import useConditionalNavigate from "./custom_hooks/use_conditional_navigate";

const Categories = ({ setCategoryId = () => {} }) => {
  const [category, setCategory] = useState({});
  const location = useLocation().pathname.split("/");
  const _id = location[location.length - 1];
  useEffect(() => {
    const getCategory = async () => {
      try {
        const res = await publicAPI.getCategory(_id);
        setCategory(res.data);
        setCategoryId(res.data._id || "");
      } catch (err) {
        console.log(err);
      }
    };
    getCategory();
  }, [_id]);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Description
        data={_id ? category?.description : ""}
        captionText={category?.title}
      ></Description>
      {category?.categories?.length > 0 && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          {category?.categories?.map((category) => (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Category {...category}></Category>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const Category = ({
  _id = "",
  title = "",
  imageURL = "",
  itemsIn = "",
  isSmall = false,
}) => {
  const navigate = useConditionalNavigate();
  return (
    <div
      onClick={
        !isSmall
          ? (e) => {
              navigate("/categories/" + _id, e);
            }
          : () => {}
      }
      className={s2.vertigo}
      style={{
        position: "relative",
        overflow: "hidden",
        width: isSmall ? "200px" : "230px",
        height: "280px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "10px",
      }}
    >
      <div
        style={{
          flexGrow: "1",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{
            maxHeight: isSmall ? "150px" : "200px",
            maxWidth: "100%",
          }}
          src={`${HOST}/${imageURL}`}
        ></img>

        {itemsIn !== 0 && (
          <div
            className={s2.circle}
            style={{
              position: "absolute",
              zIndex: "2",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              backgroundColor: "rgba(245, 100, 62, 0.7)",
              color: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {itemsIn}
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <span className="text_hover">{title}</span>
      </div>
    </div>
  );
};

export default Categories;
