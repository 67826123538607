import React from "react";
import { useNavigate } from "react-router-dom";
import useConditionalNavigate from "./custom_hooks/use_conditional_navigate";

const SearchResults = ({ filter = [], setFilter = () => {}, setInputValue = () => {} }) => {
  const navigate = useConditionalNavigate();
  return (
    <div
      style={{
        color: "black",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: "75px",
        width: "520px",
        zIndex: "2",
        border: "1px solid #f0f4fd",
        borderRadius: "3px",
      }}
    >
      {filter.map((product, index) => {
        return (
          index < 10 && (
            <span
              onClick={() => {
                navigate("/product/" + product._id)
                setFilter([])
                setInputValue("")
              }}
              style={{ padding: "10px", borderBottom: "1px solid #f0f4fd" }}
            >
              {product.title}
            </span>
          )
        );
      })}
    </div>
  );
};

export default SearchResults;
