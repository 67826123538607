const Schedule = ({data = {}}) => {
  return (
    <div style={{ border: "0.5px solid #C1C1C1" }}>
      <div style={{ padding: "5px 20px", backgroundColor: "#333" }}>
        <h6 style={{ color: "#fff" }}>
          <span style={{ color: "#69B316" }}>Г</span>рафік роботи
        </h6>
      </div>
      <ul
        style={{
          listStyleType: "none",
          padding: "10px 20px",
        }}
      >
        <li style={{ display: "flex", padding: "5px 0" }}>
          <span style={{ width: "105px" }}>Понеділок</span>
          <span>{data?.Monday}</span>
        </li>
        <li style={{ display: "flex", padding: "5px 0" }}>
          <span style={{ width: "105px" }}>Вівторок</span>
          <span>{data?.Tuesday}</span>
        </li>
        <li style={{ display: "flex", padding: "5px 0" }}>
          <span style={{ width: "105px" }}>Середа</span>
          <span>{data?.Wednesday}</span>
        </li>
        <li style={{ display: "flex", padding: "5px 0" }}>
          <span style={{ width: "105px" }}>Четвер</span>
          <span>{data?.Thursday}</span>
        </li>
        <li style={{ display: "flex", padding: "5px 0" }}>
          <span style={{ width: "105px" }}>П'ятниця</span>
          <span>{data?.Friday}</span>
        </li>
        <li style={{ display: "flex", padding: "5px 0" }}>
          <span style={{ width: "105px" }}>Субота</span>
          <span>{data?.Saturday}</span>
        </li>
        <li style={{ display: "flex", padding: "5px 0" }}>
          <span style={{ width: "105px" }}>Неділя</span>
          <span>{data?.Sunday}</span>
        </li>
      </ul>
    </div>
  );
};

export default Schedule;
