import { useEffect, useState } from "react";
import HOST from "../env";

const CartItem = ({
  title = "",
  imageURL = "",
  price = "",
  discountPrice = "",
  discountPeriod = { start: null, end: null },
  isAvailable = "",
  count = 1,
  cart = [],
  setCart = () => {},
}) => {
  const isPeriodValid = () => {
    const startNumber = new Date(discountPeriod?.start).getTime();
    const endNumber = new Date(discountPeriod?.end).getTime();
    const nowNumber = new Date().getTime();
    return nowNumber < endNumber && endNumber - startNumber > 0;
  };
  const changeCount = (count) => {
    let index;
    cart.find((product, i) => {
      if (product.title === title) index = i;
    });
    const newCart = [...cart];
    if (index !== undefined) {
      newCart[index].count = count;
      setCart(newCart);
    }
  };
  const deleteItemFromCart = () => {
    setCart(cart.filter((product) => product.title !== title));
  };
  const [Count, setCount] = useState(count);
  useEffect(() => {
    setCount(count);
  }, [count]);
  return (
    <div
      style={{
        display: "flex",
        padding: "12px 0",
        borderBottom: "0.5px solid #e8e8e8",
        borderTop: "0.5px solid #e8e8e8",
      }}
    >
      <div>
        <img
          style={{ width: "88px", marginRight: "10px" }}
          src={`${HOST}/${imageURL}`}
        ></img>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: "10px" }}>{title}</span>
          <div onClick={() => deleteItemFromCart()}>
            <svg
              style={{
                width: "24px",
                height: "24px",
                color: "#acacbe",
                outline: "none",
              }}
              viewBox="0 0 24 24"
              fill="none"
              data-testid="SvgDelete"
              data-qaid="SvgDelete"
              focusable="false"
              aria-hidden="true"
              tabindex="-1"
            >
              <path
                fill="currentColor"
                d="M12 3c.6 0 1 .4 1 1v1h6v3h1c.6 0 1 .4 1 1s-.4 1-1 1h-1v8c0 1.7-1.3 3-3 3H8c-1.7 0-3-1.3-3-3v-8H4c-.6 0-1-.4-1-1s.4-1 1-1h1V5h6V4c0-.5.4-.9.9-1h.1Zm5 7H7v8c0 .6.4 1 1 1h8c.6 0 1-.4 1-1v-8Zm-3 2c.6 0 1 .4 1 1v3c0 .6-.4 1-1 1s-1-.4-1-1v-3c0-.6.4-1 1-1Zm-4 0c.6 0 1 .4 1 1v3c0 .6-.4 1-1 1s-1-.4-1-1v-3c0-.6.4-1 1-1Zm7-5H7v1h10V7Z"
              ></path>
            </svg>
          </div>
        </div>
        {isAvailable ? (
          <span style={{ fontSize: "12px" }}>В наявності</span>
        ) : (
          <span>Немає в наявності</span>
        )}
        <span style={{ color: "#acacbe" }}>{discountPrice && isPeriodValid() ? discountPrice : price}</span>
        <div style={{ display: "flex" }}>
          <div>
            <button
              onClick={() => changeCount(Count - 1)}
              style={{
                width: "32px",
                height: "32px",
                background: "white",
                border: "1px solid #69b316",
                borderRadius: "8px",
                fontSize: "20px",
                color: "#69b316",
              }}
            >
              -
            </button>
            <input
              onInput={(e) => {
                const value = e.target.value;
                if (value === "") setCount(value);
                else if (Number.isInteger(+value)) setCount(+value);
              }}
              onBlur={(e) => {
                if (Count === "" || !Number.isInteger(+Count)) setCount(count);
                else changeCount(Count);
              }}
              style={{
                textAlign: "center",
                outline: "none",
                border: "none",
                width: "32px",
                height: "32px",
              }}
              value={Count}
            ></input>
            <button
              onClick={() => changeCount(Count + 1)}
              style={{
                width: "32px",
                height: "32px",
                background: "white",
                border: "1px solid #69b316",
                borderRadius: "8px",
                fontSize: "20px",
                color: "#69b316",
              }}
            >
              +
            </button>
          </div>
          <div
            style={{
              flexGrow: "1",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            {discountPrice && isPeriodValid()
              ? +discountPrice * count + " ₴"
              : +price * count + " ₴"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
